<template>
    <div :class="data.Class" :style="data.Style">
        <b-collapse animation="slide" v-for="(collapse, index) of data.Data" :key="index" :open="isOpen == index" @open="isOpen = index">
            <div slot="trigger" slot-scope="props" class="flex border border-solid border-t-0 border-r-0 border-l-0" role="button">
                <p class="card-header-title" :class="collapse.TitleClass">
                    {{ collapse.Title }}
                </p>
                <a class="card-header-icon">
                    <b-icon
                        :icon="props.open ? collapse.IconOpen : collapse.IconOpen">
                    </b-icon>
                </a>
            </div>
            <div class="card-content">
                <div class="content">
                    {{ collapse.Value }}
                </div>
            </div>
        </b-collapse>
    </div>
</template>
<script>
export default {
    props: {
        data: {
            type: Object
        }
    },
    data () {
        return {
            isOpen: 0,
            collapses: [
            {
                title: 'Title 1',
                text: 'Text 1'
            },
            {
                title: 'Title 2',
                text: 'Text 2'
            },
            {
                title: 'Title 3',
                text: 'Text 3'
            }
            ]
        }
    }
}
</script>
